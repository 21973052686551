@import './variables';

.auth_container{
    background-image: url('../images/bg/login_bg.png');
    min-height: 100vh;
    min-width: 1440;
    width: 100%;
    background-position: 150px  center;
    background-repeat: no-repeat;
    background-size: 90%;
    background-attachment: fixed;
    display: flex;
    flex-wrap: wrap;
}
.auth_bg{
    background: $brand_color1;
    width: 600px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth_bg_content{
    max-width: 400px;
    width: 100%;
    padding: 15px;
}

.auth_bg_content{
    h3{
        font-weight: 500;
        font-size: 34px;
        color: $white_color;
        margin-bottom: 10px;
    }
    h5{
        font-weight: 300;
        font-size: 24px;
        color: $light_color;
    }

    .form-label{
        color: $brand_color2;
        font-weight: 400;
        font-size: 16px;
    }
    .form-group{
        margin-top: 20px;
    }
    .form-control{
        height: 56px;
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.05) !important;
        color: $white_color_half;
        font-weight: 400;
        font-size: 16px;
        border: 0px;
    }
    input.form-control:-webkit-autofill,
    input.form-control:-webkit-autofill:hover, 
    input.form-control:-webkit-autofill:focus,
    textarea.form-control:-webkit-autofill,
    textarea.form-control:-webkit-autofill:hover,
    textarea.form-control:-webkit-autofill:focus,
    select.form-control:-webkit-autofill,
    select.form-control:-webkit-autofill:hover,
    select.form-control:-webkit-autofill:focus {
    border: 1px solid $white_color_half;
    -webkit-text-fill-color: $white_color_half;
    background-color: $white_color_half;
    -webkit-box-shadow: rgba(255, 255, 255, 0.05) inset !important;
    transition: background-color 5000s ease-in-out 0s;
    border: 0px;
    }
    input.form-control:-internal-autofill-selected {
        appearance: menulist-button;
        background-image: none !important;
        background-color: -internal-light-dark(rgba(232, 240, 254, 0.05), rgba(251, 251, 251, 0.4)) !important;
        color: fieldtext !important;
        border: 0px;
    }
    .form-check-input{
        width: 20px;
        height: 20px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 6px;
    }
    .form-check-label{
        color: $white_color;
        font-weight: 500;
        font-size: 14px;
        padding-left: 10px;
    }
    a{
        text-decoration: none;
        color: $brand_color3;
        font-weight: 500;
        font-size: 14px;
    }
    .btn-info,
    .btn-info:hover{
        height: 59px;
        border-color: $brand_color2;
        background: $brand_color2;
        border-radius: 14px;
        font-weight: 500;
        font-size: 18px;
        color: $brand_color1;
    }
    .btn-info:hover{
        opacity: 0.7;
    }
    p{
        text-align: center;
        color: $white_color;
        font-weight: 500;
        font-size: 18px;
    }
}













.auth_brand{
    width: calc(100% - 600px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth_brand_bg{
    background-color: $brand_color1;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 55px;
}
.AuthBackToPageBtn{
    position: absolute;
    width: 165px;
    height: 50px;
    right: 39px;
    top: 39px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white_color;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    .text{
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: $white_color;
    }
    .icon{
        color: $white_color;
        // width: 30px;
        height: 30px;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 15px;
            height: 15px;
        }
    }
}
@media (min-width: 1400px) {    
    .auth_container{
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}
@media screen and (max-width: 600px){
    .auth_bg{
        width: 100%;
        min-height: 100vh;
        padding: 10px;
    }
    .auth_brand_bg{
        display: none;
    }
    .auth_container{
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
    }
    .AuthBackToPageBtn{
        position: absolute;
        width: 140px;
        height: 45px;
        right: 10px;
        top: 50px;
    }
}