@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
/* ================================================= */
/* ================================================= */
@import './Variables';
.text_brand_color1{
  color: $brand_color1 !important; 
}
.text_brand_color2{
  color: $brand_color2 !important; 
}
.text_brand_color3{
  color: $brand_color3 !important; 
}
.text_brand_color4{
  color: $brand_color4 !important; 
}
.text_brand_color5{
  color: $brand_color5 !important; 
}
.text_brand_color6{
  color: $brand_color6 !important; 
}
.text_brand_color7{
  color: $brand_color7 !important; 
}
.text_brand_color8{
  color: $brand_color8 !important; 
}

.text_brand_dark_color{
  color: $brand_dark_color !important; 
}
.text_white_color{
  color: $white_color !important; 
}
.text_light_color{
  color: $light_color !important; 
}
.text_light_color2{
  color: $light_color2 !important; 
}
.text_white_color_half{
  color: $white_color_half !important; 
}
.text_white_opacity05{
  color: $white_opacity05 !important; 
}
.text_white_opacity3{
  color: $white_opacity3 !important; 
}
.text_white_opacity7{
  color: $white_opacity7 !important;
}

.text_white_opacity1{
  color: $white_opacity1 !important;
}




.fs-8,
.fs_8{
  font-size: 8px !important; 
}

.fs-10,
.fs_10{
  font-size: 10px !important; 
}
.fs-12,
.fs_12{
  font-size: 12px !important; 
}
.fs-14,
.fs_14{
  font-size: 14px !important; 
}
.fs-16,
.fs_16{
  font-size: 16px !important; 
}
.fs-18,
.fs_18{
  font-size: 18px !important; 
}
.fs-20,
.fs_20{
  font-size: 20px !important; 
}
.fs-22,
.fs_22{
  font-size: 22px !important; 
}
.fs-24,
.fs_24{
  font-size: 24px !important; 
}
.fs-26,
.fs_26{
  font-size: 26px !important; 
}
.fs-28,
.fs_28{
  font-size: 28px !important; 
}
.fs-30,
.fs_30{
  font-size: 30px !important; 
}
.fs-35,
.fs_35{
  font-size: 35px !important; 
}
.fw-300,
.fw_300{
  font-weight: 300 !important; 
}
.fw-400,
.fw_400{
  font-weight: 400 !important; 
}
.fw-500,
.fw_500{
  font-weight: 500 !important; 
}
.fw-600,
.fw_600{
  font-weight: 600 !important; 
}
.fw-700,
.fw_700{
  font-weight: 700 !important; 
}
.fw-800,
.fw_800{
  font-weight: 800 !important; 
}