@import './variables';

.top_header_brand{
    display: none;
}
@media screen and (max-width: 600px){
    .sidebar_container{
        position: fixed;
        z-index: +99;
        left: -350px;
        transition-duration: 500ms;
    }
    .sidebar_container.sidebar_deactive{
        left: 0px;
    }
    .main_body_container{
        width: 100%;
    }
    .top_header{
        position: relative;
        margin-bottom: 20px;
    }
    .top_header_brand{
        background-color: #242529;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
    }
    .btn.toggle {
        display: flex;
    }
    
}



// .btn-check:checked+.btn{
//     background: green;
// }




